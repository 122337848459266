//
// Base styles
//

.alert {
  // scss-docs-start alert-css-vars
  --#{$prefix}alert-bg: transparent;
  --#{$prefix}alert-padding-x: #{$alert-padding-x};
  --#{$prefix}alert-padding-y: #{$alert-padding-y};
  --#{$prefix}alert-margin-bottom: #{$alert-margin-bottom};
  --#{$prefix}alert-color: inherit;
  --#{$prefix}alert-border-color: transparent;
  --#{$prefix}alert-border: #{$alert-border-width} solid var(--#{$prefix}alert-border-color);
  --#{$prefix}alert-border-radius: #{$alert-border-radius};
  --#{$prefix}alert-link-color: inherit;
  // scss-docs-end alert-css-vars

  position: relative;
  padding: .75rem;
  margin-bottom: var(--#{$prefix}alert-margin-bottom);
  font-size: .75rem;
  color: var(--#{$prefix}alert-color);
  background-color: var(--#{$prefix}alert-bg);
  border: var(--#{$prefix}alert-border);
  @include border-radius(var(--#{$prefix}alert-border-radius));
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: inherit;
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
  color: var(--#{$prefix}alert-link-color);
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: $alert-dismissible-padding-r;

  // Adjust close link position
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $stretched-link-z-index + 1;
    //padding: $alert-padding-y * 1.25 $alert-padding-x;
    height: 1.125rem;
    padding: .75rem;
    font-size: .875rem;
  }
}


// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert
@each $state in map-keys($theme-colors) {
  .alert-#{$state} {
    --#{$prefix}alert-color: var(--#{$prefix}#{$state}-text);
    --#{$prefix}alert-bg: var(--#{$prefix}#{$state}-bg-subtle);
    //--#{$prefix}alert-link-color: var($black);

    @if ($state == "primary") {
      color: $black;
      background-color: $light-gray;
    }
    @if ($state == "warning") {
      background-color: $gold-100;
    }
    @if ($state == "success") {
      background-color: $green-100;
    }
    @if ($state == "danger") {
      background-color: $red-100;
    }
    @if ($state == "info") {
      background-color: $blue-100;
    }

    .alert-link {
      font-weight: 400;
      color: $black;
    }
  }
}
// scss-docs-end alert-modifiers

// Range
//
// Style range inputs the same across browsers. Vendor-specific rules for pseudo
// elements cannot be mixed. As such, there are no shared styles for focus or
// active states on prefixed selectors.

.form-range {
  display: inline-block;
  width: $form-range-track-width;
  height: $form-range-track-height;
  margin-right: 15px;
  font-size: 1em;
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
  vertical-align: middle;
  background: $gray-300;
  background-image: linear-gradient($form-range-track-bg, $form-range-track-bg);
  background-repeat: no-repeat;
  background-size: 70% 100%;
  // stylelint-disable-next-line property-disallowed-list
  border-radius: $form-range-track-border-radius;

  &:hover {
    background-image: linear-gradient($dark-green-700, $dark-green-700);

    &::-webkit-slider-thumb {
      background-color: $dark-green-700;
    }

    &::-moz-range-thumb {
      background-color: $dark-green-700;
    }

    &::-ms-thumb {
      background-color: $dark-green-700;
    }
  }

  &:active {
    background-image: linear-gradient($dark-green-800, $dark-green-800);
  }

  /* Input Thumb */
  &::-webkit-slider-thumb {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg;
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 50%;
    box-shadow: 0 0 2px 0 #555;
  }

  &::-moz-range-thumb {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg;
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 50%;
    box-shadow: 0 0 2px 0;
    // stylelint-disable-next-line property-disallowed-list
    transition: background .3s ease-in-out;
  }

  &::-ms-thumb {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg;
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 50%;
    box-shadow: 0 0 2px 0 #555;
    // stylelint-disable-next-line property-disallowed-list
    transition: background .3s ease-in-out;
  }

  &::-webkit-slider-thumb:hover {
    background-color: $dark-green-700;
  }

  &::-moz-range-thumb:hover {
    background-color: $dark-green-700;
  }

  &::-ms-thumb:hover {
    background-color: $dark-green-700;
  }

  &:focus::-webkit-slider-thumb {
    outline: $blue solid 1px;
    outline-offset: 1px;
  }

  &:focus::-moz-range-thumb {
    outline: $blue solid 1px;
    outline-offset: 1px;
  }

  &:focus::-ms-thumb {
    outline: $blue solid 1px;
    outline-offset: 1px;
  }

  &:active::-webkit-slider-thumb {
    background-color: $dark-green-800;
  }

  &:active::-moz-range-thumb {
    background-color: $dark-green-800;
  }

  &:active::-ms-thumb {
    background-color: $dark-green-800;
  }

  /* Input Track */
  &::-webkit-slider-runnable-track  {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    background: transparent;
    border-width: 0;
    box-shadow: none;
  }

  &::-moz-range-track {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    background: transparent;
    border-width: 0;
    box-shadow: none;
  }

  &::-ms-track {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
    background: transparent;
    border-width: 0;
    box-shadow: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: .5;
  }
}

// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  // scss-docs-start nav-css-vars
  --#{$prefix}nav-link-padding-x: #{$nav-link-padding-x};
  --#{$prefix}nav-link-padding-y: #{$nav-link-padding-y};
  @include rfs($nav-link-font-size, --#{$prefix}nav-link-font-size);
  --#{$prefix}nav-link-font-weight: #{$nav-link-font-weight};
  --#{$prefix}nav-link-color: #{$nav-link-color};
  --#{$prefix}nav-link-hover-color: #{$nav-link-hover-color};
  --#{$prefix}nav-link-disabled-color: #{$nav-link-disabled-color};
  // scss-docs-end nav-css-vars

  display: flex;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  margin-bottom: 0;
  list-style: none;
}

.nav-logo {
  height: 3.5rem;
  margin-bottom: 2.25rem;
}

.nav-logo-link {
  padding: 0;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  color: $dark-green;
  border-bottom: none;

  span {
    margin-left: 1rem;
  }
  .hidden {
    display: none;
  }
  &:hover {
    background-color: transparent;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .75rem var(--#{$prefix}nav-link-padding-x);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--#{$prefix}nav-link-color);
  text-decoration: if($link-decoration == none, null, none);
  background: none;
  border: 0;
  @include transition($nav-link-transition);

  span {
    display: flex;
    align-items: center;
    margin-left: .5rem;
  }

  &.active {
    font-weight: 700;
  }

  &:hover,
  &:focus {
    color: var(--#{$prefix}nav-link-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  &:focus-visible {
    outline: 0;
    box-shadow: $nav-link-focus-box-shadow;
  }

  // Disabled state lightens text
  &.disabled {
    color: var(--#{$prefix}nav-link-disabled-color);
    pointer-events: none;
    cursor: default;
  }
}

.nav.side-nav {
  position: relative;

  .nav-item {
    display: flex;
    justify-content: space-between;
    height: 48px;
    margin-bottom: 3rem;

    .accordion {
      width: 100%;
    }
    .accordion-item {
      background-color: transparent;
      border-color: transparent;
      border-width: 0;
      @include border-radius(0);
    }
    .accordion-button {
      padding: .75rem 1rem;
      background-color: transparent;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: 1rem;

      span {
        line-height: 20px;
      }
      &:hover {
        font-weight: 700;
        background-color: $white;
      }

      &:not(.collapsed) {
        font-weight: 400;

        &::after {
          background-image: url("/static/docs/5.3/assets/minus.svg");
        }
      }

      &::after {
        background-image: url("/static/docs/5.3/assets/plus.svg");
      }
    }
    .accordion-collapse {
      margin-left: 32px;

      // stylelint-disable-next-line
      .accordion-collapse {
        margin-left: 0;
      }
    }
  }

  .nav-link {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 1rem;

    &:hover {
      font-weight: 700;
      background-color: $white;
      box-shadow: -5px 10px 16px rgba(35, 78, 51, .12);
    }

    .hidden {
      display: none;
      //max-height: 24px;
      //overflow: hidden;
      //opacity: 1;
      //// stylelint-disable-next-line property-disallowed-list
      //transition: 1s all ease-in-out;
    }
  }

  .nav-select {
    .form-select {
      width: calc(100% - 20px); // stylelint-disable-line function-disallowed-list
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      background-color: transparent;
      border: 0 solid transparent;

      &:focus {
        outline-color: transparent;
      }
    }
    .hidden {
      display: none;
    }
  }

  .nav-link-sub {
    position: relative;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      background-color: transparent;
    }

    &::before {
      position: absolute;
      left: -8px;
      flex-shrink: 0;
      width: .25rem;
      height: 40px;
      content: url("/static/docs/5.3/assets/highlight.svg");
      @include transition(var(--#{$prefix}accordion-btn-icon-transition));
    }
  }

  .nav-link-sub-small {
    font-size: .75rem;
    line-height: 1rem;

    &::before {
      margin-right: 2.875rem;
    }
  }

  .nav-link.active::before {
    position: absolute;
    flex-shrink: 0;
    width: 1.25rem;
    height: 5.938rem;
    margin: 0 1.938rem 0 -2rem;
    content: url("/static/docs/5.3/assets/active.png");
    @include transition(var(--#{$prefix}accordion-btn-icon-transition));
  }

  .nav-footer {
    position: absolute;
    bottom: 0;
  }

  &.nav-collapse {
    width: 6rem;

    .nav-logo-link span {
      display: none;
    }

    .nav-link {
      width: auto;

      //span {
      //  //width: 0;
      //  //opacity: 0;
      //  display: none;
      //}
    }

    .accordion-button {
      width: auto;

      span,
      &:not(.collapsed)::after,
      &::after {
        display: none;
      }
    }

    .accordion-collapse {
      display: none;
    }

    .form-select {
      display: none;
    }
  }

  .nav-logo {
    height: 3.5rem;
  }
}

.side-nav {
  width: 19.625rem;
  max-width: 19.625rem;
  height: 100vh;
  background-color: $light-gray;
  // stylelint-disable-next-line property-disallowed-list
  transition: width .25s ease-in-out;
}
//
// Tabs
//

.nav-tabs {
  // scss-docs-start nav-tabs-css-vars
  --#{$prefix}nav-tabs-border-width: #{$nav-tabs-border-width};
  --#{$prefix}nav-tabs-border-color: #{$nav-tabs-border-color};
  --#{$prefix}nav-tabs-border-radius: #{$nav-tabs-border-radius};
  --#{$prefix}nav-tabs-link-hover-border-color: #{$nav-tabs-link-hover-border-color};
  --#{$prefix}nav-tabs-link-active-color: #{$nav-tabs-link-active-color};
  --#{$prefix}nav-tabs-link-active-bg: #{$nav-tabs-link-active-bg};
  --#{$prefix}nav-tabs-link-active-border-color: #{$nav-tabs-link-active-border-color};
  // scss-docs-end nav-tabs-css-vars

  position: relative;
  border-bottom: var(--#{$prefix}nav-tabs-border-width) solid var(--#{$prefix}nav-tabs-border-color);

  .nav-item {
    &:hover {
      margin-bottom: -1px;
      border-bottom: 1px solid $black;

      .nav-link {
        color: $black;
      }
    }

    &:focus {
      outline: $blue solid 1px;
      outline-offset: 1px;
    }
  }

  .nav-link {
    margin-bottom: calc(-1 * var(--#{$prefix}nav-tabs-border-width)); // stylelint-disable-line function-disallowed-list
    color: $dark-gray;
    border: var(--#{$prefix}nav-tabs-border-width) solid transparent;
    @include border-top-radius(0);
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 4px;

    &:hover,
    &:focus {
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
    }

    &:hover {
      color: $black;
      background-color: transparent;
    }

    &.disabled,
    &:disabled {
      color: $dark-gray;
      background-color: transparent;
      opacity: .5;

      &:hover {
        border-color: transparent;
      }
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    margin-bottom: -1px;
    font-weight: 700;
    color: $dark-green;
    border-bottom: 1px solid $black;
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 0;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: calc(-1 * var(--#{$prefix}nav-tabs-border-width)); // stylelint-disable-line function-disallowed-list
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}


//
// Pills
//

.nav-pills {
  // scss-docs-start nav-pills-css-vars
  --#{$prefix}nav-pills-border-radius: #{$nav-pills-border-radius};
  --#{$prefix}nav-pills-link-active-color: #{$nav-pills-link-active-color};
  --#{$prefix}nav-pills-link-active-bg: #{$nav-pills-link-active-bg};
  // scss-docs-end nav-pills-css-vars

  .nav-link {
    @include border-radius(var(--#{$prefix}nav-pills-border-radius));

    &:disabled {
      color: var(--#{$prefix}nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: var(--#{$prefix}nav-pills-link-active-color);
    @include gradient-bg(var(--#{$prefix}nav-pills-link-active-bg));
  }
}


//
// Underline
//

.nav-underline {
  // scss-docs-start nav-underline-css-vars
  --#{$prefix}nav-underline-gap: #{$nav-underline-gap};
  --#{$prefix}nav-underline-border-width: #{$nav-underline-border-width};
  --#{$prefix}nav-underline-link-active-color: #{$nav-underline-link-active-color};
  // scss-docs-end nav-underline-css-vars

  gap: var(--#{$prefix}nav-underline-gap);

  // .nav-item + .nav-item,
  // .nav-link + .nav-link {
  //   margin-left: $nav-link-padding-x;
  // }

  .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--#{$prefix}nav-underline-border-width) solid transparent;

    &:hover,
    &:focus {
      border-bottom-color: currentcolor;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    font-weight: $font-weight-bold;
    color: var(--#{$prefix}nav-underline-link-active-color);
    border-bottom-color: currentcolor;
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

.nav-fill,
.nav-justified {
  .nav-item .nav-link {
    width: 100%; // Make sure button will grow
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

// Loop over each breakpoint
@each $breakpoint in map-keys($grid-breakpoints) {

  // Generate media query if needed
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Loop over each utility property
    @each $key, $utility in $utilities {
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Only proceed if responsive media queries are enabled or if it's the base media query
      @if type-of($utility) == "map" and (map-get($utility, responsive) or $infix == "") {
        @include generate-utility($utility, $infix);
      }
    }
  }
}

// RFS rescaling
@media (min-width: $rfs-mq-value) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @if (map-get($grid-breakpoints, $breakpoint) < $rfs-breakpoint) {
      // Loop over each utility property
      @each $key, $utility in $utilities {
        // The utility can be disabled with `false`, thus check if the utility is a map first
        // Only proceed if responsive media queries are enabled or if it's the base media query
        @if type-of($utility) == "map" and map-get($utility, rfs) and (map-get($utility, responsive) or $infix == "") {
          @include generate-utility($utility, $infix, true);
        }
      }
    }
  }
}

// Print utilities
@media print {
  @each $key, $utility in $utilities {
    // The utility can be disabled with `false`, thus check if the utility is a map first
    // Then check if the utility needs print styles
    @if type-of($utility) == "map" and map-get($utility, print) == true {
      @include generate-utility($utility, "-print");
    }
  }
}
//
//@font-face {
//  font-family: Speedee;
//  font-style: normal;
//  font-weight: 300;
//  src: url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Lt.eot");
//  src: url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Lt.eot?#iefix") format("embedded-opentype"), url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Lt.woff2") format("woff2"), url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Lt.woff") format("woff");
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}
//
//@font-face {
//  font-family: Speedee;
//  font-style: normal;
//  font-weight: 400;
//  src: url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Rg.eot");
//  src: url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Rg.eot?#iefix") format("embedded-opentype"), url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Rg.woff2") format("woff2"), url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Rg.woff") format("woff");
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}
//
//@font-face {
//  font-family: Speedee;
//  font-style: normal;
//  font-weight: 700;
//  src: url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Bd.eot");
//  src: url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Bd.eot?#iefix") format("embedded-opentype"), url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Bd.woff2") format("woff2"), url("/static/docs/5.3/assets/fonts/speedee/Speedee_W_Bd.woff") format("woff");
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}

